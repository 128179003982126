import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"

const Page = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Paragraph = styled.p`
  margin-top: 0;
`

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />
    <Page>
      <h1>Oops..</h1>
      <Paragraph>Looks like you found a page that doesn't exist.</Paragraph>
      <Img fixed={data.file.childImageSharp.fixed} />
    </Page>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "max-curtains.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
NotFoundPage.propTypes = {
  data: PropTypes.object,
}

export default NotFoundPage
